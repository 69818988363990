import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import TransferLogsTable from './TransferLogsTable'
import { transferLogsNav } from '../../state/navSlice'
import Breadcrumbs from '../Breadcrumbs'

const TransferLogsListPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(transferLogsNav())
  }, [dispatch])

  return (
    <>
      <Breadcrumbs/>
      <Row>
        <Col span={16}><p>Transfer logs are events that happened during the execution of your
          active file transfer tasks. Once the file is successfully transferred or deleted -
          you&apos;ll find a related event in this table.</p></Col>
      </Row>
      <TransferLogsTable/>
    </>
  )
}

export default TransferLogsListPage
