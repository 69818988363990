import axios from 'axios'
import { BASE_PATH, LOCAL_STORAGE_TOKEN_KEY, WEB_API } from '../constants'

/**
 * Returns a default Axios client with all defaults pre-configured
 *
 * @param host
 * @returns
 */
const getDefaultHttpClient = (host) => {
  const cl = axios.create({
    baseURL: host,
    // timeout: 1000,
    // headers: { 'X-App': 'FTSol' },
  })
  cl.interceptors.request.use(
    async config => {
      let token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)

      // TODO: figure out this
      // if (!token) {
      //   return
      // }
      config.headers['Authorization'] = 'Bearer ' + token

      return config
    },
    error => {
      console.error('cl.interceptors.request error', error)
    },
  )
  cl.interceptors.response.use(
    async config => config,
    error => {
      if (error.response.status === 401) {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
        if (!document.location.pathname.startsWith(BASE_PATH + '/sign_in')) {
          document.location.href = BASE_PATH + '/sign_in'
        }
      }
    },
  )

  return cl
}

const client = getDefaultHttpClient(WEB_API + '/api')

export default client
