import { Button, Form, Input, Typography } from 'antd'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  accountData,
  createStripeUser,
  getAccountDetails,
} from '../../lib/FTWebApi'
import CustomSpinner from '../CustomSpinner'
import { openError, openSuccess } from '../Notification'

const requiredRule = [{ required: true } ]

const CreateUserContent = () => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()

  const { isLoading: isLoadingAccountData, data: fetchedAccountData } =
    useQuery(accountData, getAccountDetails)

  const { mutate: mutateCreateUser, isLoading: createUserIsLoading } = useMutation(createStripeUser(), {
    onError: (err) => {
      console.error(err.response.data)
      let errMsg = `There was an error updating a Subscription user: ${err.message}`
      const responseErr = err.response && err.response.data && err.response.data.error
      if (responseErr) errMsg += ` ${responseErr}`
      openError('Error', errMsg)
    },
    onSettled: () => {
      queryClient.invalidateQueries(accountData).then()
    },
    onSuccess: () => {
      openSuccess('Success', 'Subscription user has been successfully created!')
    },
  })

  const onFinish = (values) => {
    mutateCreateUser(values)
  }

  const isLoading = isLoadingAccountData || createUserIsLoading
  const initialValues = {
    Email: fetchedAccountData.Email,
    Name: fetchedAccountData.Name,
  }

  return (
    <div className="steps-content">
      <Typography>
        <Typography.Text>
          In order to create a subscription, we need a couple of details from you.
          Please, enter you name, email and billing address.
        </Typography.Text>
      </Typography>
      <Form
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 8 }}
        layout="horizontal"
        disabled={isLoading}
        onFinish={onFinish}
        initialValues={initialValues}
        form={form}
        style={{ marginTop: 15 }}
      >
        <Form.Item label="Name" name={'Name'} required rules={requiredRule}>
          <Input placeholder="Jason Born"/>
        </Form.Item>
        <Form.Item label="Email" name={'Email'} required rules={requiredRule}>
          <Input placeholder="your@email.com"/>
        </Form.Item>
        <Form.Item label="Street" name={'Street'}>
          <Input placeholder="1234 Something St"/>
        </Form.Item>
        <Form.Item label="City" name={'City'}>
          <Input placeholder="Beverly Hills"/>
        </Form.Item>
        <Form.Item label="State" name={'State'}>
          <Input placeholder="CA"/>
        </Form.Item>
        <Form.Item label="Country" name={'Country'}>
          <Input placeholder="United States"/>
        </Form.Item>
        <Form.Item label="Postal code" name={'PostalCode'} required rules={requiredRule}>
          <Input placeholder="90210"/>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Create Subscription user
          </Button>
        </Form.Item>
        { createUserIsLoading && <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
          <CustomSpinner/>
        </Form.Item> }
      </Form>
    </div>
  )
}

export default CreateUserContent
