import { notification } from 'antd'

const openWarning = (title, description) => {
  notification['warning']({
    description: description,
    message: title,
  })
}
const openError = (title, description) => {
  notification['error']({
    description: description,
    message: title,
  })
}
const openInfo = (title, description) => {
  notification['info']({
    description: description,
    message: title,
  })
}
const openSuccess = (title, description) => {
  notification['success']({
    description: description,
    message: title,
  })
}

export {
  openWarning,
  openError,
  openInfo,
  openSuccess,
}
