import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd'
import React from 'react'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { openError, openSuccess } from './Notification'
import { BASE_PATH, LOCAL_STORAGE_TOKEN_KEY } from '../constants'
import { signUp } from '../lib/FTWebApi'
import { extractErr } from '../lib/helpers'

const SignUp = () => {
  const { mutate: mutateSignUp, isLoading: signUpIsLoading } = useMutation(signUp(), {
    onError: (err) => {
      openError('Error', extractErr(err, 'There was an error creating a user:'))
    },
    onSuccess: (data) => {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, data.token)
      openSuccess('Success', 'User has been successfully registered! You will be ' +
        'redirected soon.')
      setTimeout(() => document.location.href = BASE_PATH + '/account/details', 2000)
    },
  })

  const onFinish = (values) => {
    mutateSignUp(values)
  }
  const onFinishFailed = (errorInfo) => {
    console.warn('errors on the form:', errorInfo)
  }

  return <>
    <Row justify="center">
      <Col span={8}>
        <Divider style={{ marginTop: 100 }}>Sign up for FileBro.io</Divider>
      </Col>
    </Row>
    <Row justify="center" style={{ marginTop: 20 }}>
      <Col span={4}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="Email"
            rules={[
              {
                message: 'Please input your email!',
                required: true,
                type: 'email',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Name"
            name="Name"
            rules={[
              {
                message: 'Please input your name!',
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="Password"
            rules={[
              {
                message: 'Please input your password!',
                required: true,
              },
              {
                min: 6,
                type: 'string',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" disabled={signUpIsLoading} htmlType="submit">
              Sign up
            </Button>
          </Form.Item>
        </Form>
        <Typography type="primary">
          Already a user? <Link to="/sign_in" component={Typography.Link}>Sign in!</Link>
        </Typography>
      </Col>
    </Row>
  </>
}

export default SignUp
