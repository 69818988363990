import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Space, Table, Tag } from 'antd'
import React  from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { shareTypeMap } from '../../enums'
import { deleteShare, getShares, sharesData } from '../../lib/FTWebApi'
import { openError, openSuccess } from '../Notification'

const confirmShareDeletion = (id, name, okCbk) => {
  Modal.confirm({
    cancelText: 'Cancel',
    content: `You are about to delete the share "${name}". If it's used in any of the tasks you would have 
    to delete them first. Are you sure you want to do that?`,
    icon: <ExclamationCircleOutlined/>,
    okText: 'OK',
    onOk: okCbk,
    title: 'Confirm',
  })
}

const ShareTable = () => {
  const queryClient = useQueryClient()
  const { isLoading, data } = useQuery(sharesData, getShares)
  const finalData = (data && data.length) ? data : []

  const { mutate: mutateDelete, isLoading: deleteIsLoading } = useMutation(deleteShare(), {
    onError: (err) => {
      console.error(err.response.data)
      if (err.response.data && err.response.data.tasks) {
        let errEl = <>
          There was an error deleting a File Share: share is assigned to tasks - {
            err.response.data.tasks.map((el, key) =>
              <>
                <a key={key} href={`/tasks/${el.Id}`}>{ el.Name }</a>
                { err.response.data.tasks.length !== 1 && key !== (err.response.data.tasks.length - 1) && ', ' }
              </>,
            ) }
        </>
        openError('Error', errEl)
      } else {
        let errMsg = `There was an error deleting a File Share: ${err.message}`
        const responseErr = err.response && err.response.data && err.response.data.error
        if (responseErr) errMsg += ` ${responseErr}`
        openError('Error', errMsg)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(sharesData).then()
    },
    onSuccess: () => {
      openSuccess('Success', 'File Share has been successfully deleted!')
    },
  })

  const columns = [
    {
      dataIndex: 'Id',
      key: 'Id',
      title: 'ID',
    },
    {
      dataIndex: 'Name',
      key: 'Name',
      title: 'Name',
    },
    {
      dataIndex: 'Type',
      key: 'Type',
      render: (t) => {
        return <Tag>{ shareTypeMap[t] }</Tag>
      },
      title: 'Type',
    },
    {
      dataIndex: 'Server',
      key: 'Server',
      title: 'Server',
    },
    {
      dataIndex: 'Port',
      key: 'Port',
      title: 'Port',
    },
    {
      dataIndex: 'Resource',
      key: 'Resource',
      title: 'Resource',
    },
    {
      dataIndex: 'Path',
      key: 'Path',
      title: 'Path',
    },
    {
      dataIndex: 'Login',
      key: 'Login',
      title: 'Login',
    },
    {
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link"><Link to={`/shares/${record.Id}`}><EditOutlined/></Link></Button>
          <Button
            type="text"
            onClick={() => confirmShareDeletion(record.Id, record.Name, () => mutateDelete(record.Id))}
            shape="circle"
            icon={<DeleteOutlined/>}
            danger
          />
        </Space>
      ),
      title: 'Action',
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={finalData}
        rowKey={'Id'}
        loading={isLoading || deleteIsLoading}
      />
    </>
  )
}

export default ShareTable
