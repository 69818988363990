import {
  CloudUploadOutlined,
  ShareAltOutlined,
  SwapOutlined,
  SyncOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Menu, Typography } from 'antd'
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const { Title } = Typography

function getItem(label, key, icon, children) {
  return {
    children,
    icon,
    key,
    label,
  }
}

const items = [
  getItem('File Shares', '/shares', <ShareAltOutlined />),
  getItem('Sync Tasks', '/tasks', <SyncOutlined />),
  getItem('Transfer Logs', '/transfer_logs', <CloudUploadOutlined />),
  getItem('Account', '/account/details', <UserOutlined />,
  //   [
  // //   getItem('Details', '/account/details'),
  // //   getItem('Billing', '/account/billing'),
  // //   getItem('Change Password', '/account/password'),
  // // ]
  ),
]

const SideMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div>
      <Typography>
        <Title>
          <Link to={'/'}>
            <SwapOutlined /> filebro
          </Link>
        </Title>
      </Typography>
      <Menu
        theme="dark"
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        items={items}
        onClick={(option) => navigate(option.key)}
      />
    </div>
  )
}

export default SideMenu
