import React from 'react'

const PageFooter = () => {
  return (
    <div>
      <h1>Filebro Inc., 2022, all rights reserved</h1>
    </div>
  )
}

export default PageFooter
