import client from './httpClient'
import { WEB_API } from '../constants'

const tasksData = 'tasksData'
const taskData = (id) => `tasksData/${id}`
const sharesData = 'sharesData'
const shareData = (id) => `sharesData/${id}`
const accountData = 'accountData'
const subscriptionData = 'subscriptionData'
const upcomingInvoiceData = 'upcomingInvoiceData'
const stripePaymentIntent = 'stripePaymentIntent'
const transferLogsData = 'transferLogsData'

const getTasks = () => client.get('/tasks').then((resp) => resp.data)
const getTask = (id) => () => client.get(`/tasks/${id}`).then((resp) => resp.data)
const createTask = () =>
  ({ SourceShareID, DestinationShareID, Type, Name }) => {
    return client.post('/tasks', {
      DestinationShareID, Name, SourceShareID, Type,
    }).then((resp) => resp.data)
  }
const updateTask = (id) =>
  ({ Type, Name }) => {
    return client.put(`/tasks/${id}`, { Name, Type })
      .then((resp) => resp.data)
  }
const deleteTask = () => (id) => client.delete(`/tasks/${id}`).then((resp) => resp.data)

const getShare = (id) => () => client.get(`/shares/${id}`).then((resp) => resp.data)
const getShares = () => client.get('/shares').then((resp) => resp.data)
const createShare = () =>
  ({ Name, Server, Port, Login, Password, Type, Resource, Path, AWSRegion, PrivateKey }) => {
    return client.post('/shares', {
      ExtraSettings: { AWSRegion, PrivateKey },
      Login, Name, Password, Path, Port, Resource, Server,
      Type,
    })
      .then((resp) => resp.data)
  }
const updateShare = (id) =>
  ({ Name, Server, Port, Login, Password, Type, Resource, Path, AWSRegion, PrivateKey }) => {
    return client.put(`/shares/${id}`, {
      ExtraSettings: { AWSRegion, PrivateKey },
      Login, Name, Password, Path, Port, Resource, Server,
      Type,
    })
      .then((resp) => resp.data)
  }
const deleteShare = () => (id) => client.delete(`/shares/${id}`).then((resp) => resp.data)

const getAccountDetails = () => client.get('/account/me').then((resp) => resp.data)

const getSubscription = () => client.get('/subscription')
  .then((resp) => resp.data)
  .catch((resp) => resp.response.status === 404 ? null : resp)
const cancelSubscription = () => client.delete('/subscription').then((resp) => resp.data)

const getUpcomingInvoice = () => client.get('/invoices/upcoming')
  .then((resp) => resp.data)
  .catch((resp) => resp.response.status === 404 ? null : resp)

const createStripeUser = () =>
  ({ Name, Email, Street, City, Country, PostalCode, State }) => {
    return client.post('/subscription/user', {
      City, Country, Email, Name, PostalCode, State, Street,
    })
      .then((resp) => resp.data)
  }
const getStripeUser = () => client.get('/subscription/user').then((resp) => resp.data)
const getPaymentIntentSecret = () => client.get('/subscription/secret').then((resp) => resp.data)

const signUp = () =>
  ({ Name, Email, Password }) => {
    return client.post('/sign_up', {
      Email, Name, Password,
    }, { baseURL: WEB_API }).then((resp) => resp.data)
  }
const signIn = () =>
  ({ Email, Password }) => {
    return client.post('/login', {
      Email, Password,
    }, { baseURL: WEB_API }).then((resp) => resp.data)
  }
const refreshToken = () =>
  () => {
    return client.get('/refresh_token').then((resp) => resp.data)
  }
const changePassword = () =>
  ({ CurrentPassword, NewPassword, NewPasswordRepeat }) => {
    return client.post('/account/change_password', {
      CurrentPassword, NewPassword, NewPasswordRepeat,
    }).then((resp) => resp.data)
  }
const getTransferLogs = () =>
  ({ taskIDs, limit, offsetID }) => {
    return client.get('/transfer_logs', {
      params: {
        limit,
        offset_id: offsetID,
        task_ids: taskIDs,
      },
    })
      .then((resp) => resp.data)
  }

export {
  accountData,
  shareData,
  sharesData,
  subscriptionData,
  taskData,
  tasksData,
  transferLogsData,
  upcomingInvoiceData,
  stripePaymentIntent,

  getAccountDetails,

  getUpcomingInvoice,

  getShare,
  getShares,
  createShare,
  updateShare,
  deleteShare,

  getSubscription,
  cancelSubscription,
  createStripeUser,
  getStripeUser,
  getPaymentIntentSecret,

  getTask,
  getTasks,
  createTask,
  updateTask,
  deleteTask,

  signUp,
  signIn,
  changePassword,
  refreshToken,

  getTransferLogs,
}
