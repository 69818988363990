import { KeyOutlined, LogoutOutlined } from '@ant-design/icons'
import { Button, Descriptions, Spin, Tag } from 'antd'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import UpcomingInvoice from './Subscription'
import {
  accountData,
  getAccountDetails,
  getSubscription,
  subscriptionData,
} from '../../lib/FTWebApi'
import { accountMeNav } from '../../state/navSlice'
import Breadcrumbs from '../Breadcrumbs'
import { ChangePasswordModal } from '../ChangePasswordModal'
import { confirmLogout } from '../LogoutModal'
import './styles.scss'

const AccountDetailsPage = () => {
  const dispatch = useDispatch()
  const { isLoading: isLoadingAccountData, data: fetchedAccountData } =
    useQuery(accountData, getAccountDetails)
  const { isLoading: isLoadingSubscriptionData, data: fetchedSubscriptionData } =
    useQuery(subscriptionData, getSubscription)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [daysUntilDue, setDaysUntilDue] = useState(-1)
  const [status, setStatus] = useState('')
  const [trialEnd, setTrialEnd] = useState(moment())
  const [passChangeModalVisible, setPassChangeModalVisible] = useState(false)

  useEffect(() => {
    dispatch(accountMeNav())
  }, [dispatch])

  useEffect(() => {
    if (fetchedAccountData) {
      setName(fetchedAccountData.Name)
      setEmail(fetchedAccountData.Email)
    }
  }, [fetchedAccountData])

  useEffect(() => {
    if (fetchedSubscriptionData) {
      setDaysUntilDue(fetchedSubscriptionData.days_until_due)
      setStatus(fetchedSubscriptionData.status)
      setTrialEnd(moment.unix(fetchedSubscriptionData.trial_end))
    }
  }, [fetchedSubscriptionData])

  return (
    <>
      { passChangeModalVisible && <ChangePasswordModal hideModalCbk={() => setPassChangeModalVisible(false)}/> }
      { (isLoadingAccountData || isLoadingSubscriptionData) ? <Spin tip="Loading..."/>
        : <>
          <Breadcrumbs/>
          <Descriptions title="Account Information" column={1}>
            <Descriptions.Item label="Name">{ name }</Descriptions.Item>
            <Descriptions.Item label="Email">{ email }</Descriptions.Item>
          </Descriptions>
          <Button style={{ marginBottom: 15, marginRight: 15 }} type="primary"
            onClick={() => setPassChangeModalVisible(true)}>
            <KeyOutlined/>Change Password
          </Button>
          <Button style={{ marginBottom: 15 }} danger onClick={confirmLogout}>
            <LogoutOutlined/>Logout
          </Button>
          <Descriptions title="Subscription" column={1}>
            { status === 'trialing' &&
              <Descriptions.Item label="Status"><Tag color="green">
                Trial</Tag> ends { trialEnd.toString() }</Descriptions.Item> }
            { !!fetchedSubscriptionData &&
              <Descriptions.Item label="Payment due in">{ daysUntilDue } days</Descriptions.Item> }
          </Descriptions>
        </> }
      <UpcomingInvoice/>
    </>
  )
}

export default AccountDetailsPage
