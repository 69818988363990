import { Layout } from 'antd'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import './App.scss'
import AccountDetailsPage from './components/AccountDetailsPage'
import PageFooter from './components/PageFooter'
import SharePage from './components/SharePage'
import SharesListPage from './components/SharesListPage'
import SideMenu from './components/SideMenu'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'
import TaskPage from './components/TaskPage'
import TasksListPage from './components/TasksListPage'
import TransferLogsListPage from './components/TransferLogsListPage'

import { LOCAL_STORAGE_TOKEN_KEY } from './constants'
import { refreshToken } from './lib/FTWebApi'

const { Footer, Sider, Content } = Layout

function App () {
  const isAuthenticated = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
  const location = useLocation()
  const navigate = useNavigate()
  const layoutStyles =  { minHeight: '100vh' }
  if (isAuthenticated) layoutStyles.marginLeft = 200

  useEffect(()=>{
    if (!isAuthenticated && location.pathname !== '/sign_in' && location.pathname !== '/sign_up') {
      navigate('/sign_in')
    }
  }, [navigate, isAuthenticated, location])

  const { mutate: mutateRefreshToken } = useMutation(refreshToken(), {
    onError: (err) => {
      console.error(err.response.data)
    },
    onSuccess: (data) => {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, data.token)
    },
  })

  useEffect(() => {
    mutateRefreshToken()
  }, [mutateRefreshToken])

  return (
    <Layout className={'body-content'}>
      { isAuthenticated && <Sider className={'layout-side-menu'} style={{
        bottom: 0,
        height: '100vh',
        left: 0,
        overflow: 'auto',
        position: 'fixed',
        top: 0,
      }}><SideMenu/></Sider> }
      <Layout style={layoutStyles}>
        <Content className={'layout-content'}>
          <Routes>
            <Route exact path="/" element={<Navigate to="/shares"/>} />

            <Route path="/tasks" element={<TasksListPage/>}/>
            <Route path="/tasks/:id" element={<TaskPage/>}/>
            <Route path="/tasks/create" element={<TaskPage isNew={true}/>}/>

            <Route path="/shares" element={<SharesListPage/>}/>
            <Route path="/shares/:id" element={<SharePage/>}/>
            <Route path="/shares/create" element={<SharePage isNew={true}/>}/>

            <Route path="/transfer_logs" element={<TransferLogsListPage/>}/>

            <Route exact path="/account" element={<Navigate to="/account/details"/>} />
            <Route path="/account/details" element={<AccountDetailsPage/>}/>
            <Route path="/sign_in" element={<SignIn/>}/>
            <Route path="/sign_up" element={<SignUp/>}/>
          </Routes>
        </Content>
        <Footer style={{ marginTop: 150 }}><PageFooter /></Footer>
      </Layout>
    </Layout>
  )
}

export default App
