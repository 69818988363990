import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React from 'react'
import { BASE_PATH, LOCAL_STORAGE_TOKEN_KEY } from '../constants'

const removeAuth = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
  document.location.href = BASE_PATH
}

const confirmLogout = () => {
  Modal.confirm({
    cancelText: 'No',
    content: 'Are you sure you want to log out?',
    icon: <ExclamationCircleOutlined />,
    okText: 'Yes',
    onOk: removeAuth,
    title: 'Confirm logout',
  })
}

export {
  confirmLogout,
}
