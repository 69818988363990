import { createSlice } from '@reduxjs/toolkit'
import { ACCOUNT_ICON, FILE_SHARE_ICON, HOME_ICON, SYNC_TASK_ICON, TRANSFER_LOG_ICON } from '../enums'

const homeCrumb = { breadcrumbName: 'Home', icon: HOME_ICON, path: '/' }
const accountCrumb = { breadcrumbName: 'Account', icon: ACCOUNT_ICON, path: '/account' }
const accountMeCrumb = { breadcrumbName: 'Details', path: '/details' }
const tasksCrumb = { breadcrumbName: 'Sync Tasks', icon: SYNC_TASK_ICON, path: '/tasks' }
const taskCrumb = (id, name) => ({ breadcrumbName: `${name}`, path: `/${id}` })
const sharesCrumb = { breadcrumbName: 'File Shares', icon: FILE_SHARE_ICON, path: '/shares' }
const shareCrumb = (id, name) => ({ breadcrumbName: `${name}`, path: `/${id}` })
const transferLogsCrumb = { breadcrumbName: 'Transfer Logs', icon: TRANSFER_LOG_ICON, path: '/transfer_logs' }

const initialState = {
  breadcrumbs: [
    homeCrumb,
  ],
}

export const navSlice = createSlice({
  initialState,
  name: 'nav',
  reducers: {
    accountMeNav: (state) => {
      state.breadcrumbs = [
        homeCrumb,
        accountCrumb,
        accountMeCrumb,
      ]
    },
    shareNav: (state, action) => {
      const { id, name } = action.payload
      state.breadcrumbs = [
        homeCrumb,
        sharesCrumb,
        shareCrumb(id, name),
      ]
    },
    sharesNav: (state) => {
      state.breadcrumbs = [
        homeCrumb,
        sharesCrumb,
      ]
    },
    taskNav: (state, action) => {
      const { id, name } = action.payload
      state.breadcrumbs = [
        homeCrumb,
        tasksCrumb,
        taskCrumb(id, name),
      ]
    },
    tasksNav: (state) => {
      state.breadcrumbs = [
        homeCrumb,
        tasksCrumb,
      ]
    },
    transferLogsNav: (state) => {
      state.breadcrumbs = [
        homeCrumb,
        transferLogsCrumb,
      ]
    },
  },
})

export const {
  accountMeNav,
  tasksNav,
  taskNav,
  transferLogsNav,
  sharesNav,
  shareNav,
} = navSlice.actions

export const selectNav = (state) => state.nav

export default navSlice.reducer
