import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import CreateShareButton from './CreateShareButton'
import ShareTable from './ShareTable'
import { sharesNav } from '../../state/navSlice'
import Breadcrumbs from '../Breadcrumbs'

const SharesListPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(sharesNav())
  }, [dispatch])

  return (
    <>
      <Breadcrumbs/>
      <Row>
        <Col span={16}><p>Shares that has been created in your account.
          They are needed to set up a synchronization task between them.</p></Col>
        <Col span={8}><CreateShareButton/></Col>
      </Row>
      <ShareTable/>
    </>
  )
}

export default SharesListPage
