import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd'
import React from 'react'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { openError, openSuccess } from './Notification'
import { BASE_PATH, LOCAL_STORAGE_TOKEN_KEY } from '../constants'
import { signIn } from '../lib/FTWebApi'
import { extractErr } from '../lib/helpers'

const SignIn = () => {
  const { mutate: mutateSignIn, isLoading: signInIsLoading } = useMutation(signIn(), {
    onError: (err) => {
      openError('Error', extractErr(err, 'There was an error logging in:'))
    },
    onSuccess: (data) => {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, data.token)
      openSuccess('Success', 'You have been successfully signed in! You will be ' +
        'redirected soon.')
      setTimeout(() => document.location.href = BASE_PATH + '/', 2000)
    },
  })

  const onFinish = (values) => {
    mutateSignIn(values)
  }

  const onFinishFailed = (errorInfo) => {
    console.warn('errors on the form:', errorInfo)
  }

  return <>
    <Row justify="center">
      <Col span={8}>
        <Divider style={{ marginTop: 100 }}>Sign into FileBro.io</Divider>
      </Col>
    </Row>
    <Row justify="center" style={{ marginTop: 20 }}>
      <Col span={4}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="Email"
            rules={[
              {
                message: 'Please input your email!',
                required: true,
                type: 'email',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="Password"
            rules={[
              {
                message: 'Please input your password!',
                required: true,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" disabled={signInIsLoading} htmlType="submit">
              Sign in
            </Button>
          </Form.Item>
        </Form>
        <Typography type="primary">
          Not a user yet? Click <Link to="/sign_up" component={Typography.Link}>here</Link> to sign up!
        </Typography>
      </Col>
    </Row>
  </>
}

export default SignIn
