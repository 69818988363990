import { Button, Form, Input, InputNumber, Select, Spin } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { shareTypeMap } from '../../enums'
import { createShare, getShare, shareData, sharesData, updateShare } from '../../lib/FTWebApi'
import { shareNav, sharesNav } from '../../state/navSlice'
import Breadcrumbs from '../Breadcrumbs'
import { openError, openSuccess } from '../Notification'

const SharePage = (props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { id: idParam } = useParams()
  const { isNew } = props
  const { isLoading: shareIsLoading, data: shareFetchedData } =
    useQuery(shareData(idParam), getShare(idParam), { enabled: !isNew })
  const { mutate: mutateCreate, isLoading: createIsLoading } = useMutation(createShare(), {
    onError: (err) => {
      console.error(err.response.data)
      let errMsg = `There was an error creating a File Share: ${err.message}`
      const responseErr = err.response && err.response.data && err.response.data.error
      if (responseErr) errMsg += ` ${responseErr}`
      openError('Error', errMsg)
    },
    onSettled: () => {
      queryClient.invalidateQueries(sharesData).then()
    },
    onSuccess: (data) => {
      openSuccess('Success', 'File Share has been successfully created!')
      navigate('/shares/' + data.Id)
    },
  })
  const { mutate: mutateUpdate, isLoading: updateIsLoading } = useMutation(updateShare(idParam), {
    onError: (err) => {
      console.error(err.response.data)
      let errMsg = `There was an error updating a File Share: ${err.message}`
      const responseErr = err.response && err.response.data && err.response.data.error
      if (responseErr) errMsg += ` ${responseErr}`
      openError('Error', errMsg)
    },
    onSettled: () => {
      queryClient.invalidateQueries(sharesData).then()
    },
    onSuccess: () => {
      openSuccess('Success', 'File Share has been successfully updated!')
    },
  })

  const [form] = Form.useForm()
  const [name, setName] = useState('')
  const [shareType, setShareType] = useState(null)
  const [server, setServer] = useState('')
  const [port, setPort] = useState(0)
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [resource, setResource] = useState('')
  const [path, setPath] = useState('')
  const [awsRegion, setAWSRegion] = useState('')
  const [privateKey, setPrivateKey] = useState('')

  const typeOptions = Object.keys(shareTypeMap).map((idx) => ({
    label: shareTypeMap[idx],
    value: +idx,
  }))

  useEffect(() => {
    // we don't know the details of the task yet
    dispatch(sharesNav())
  }, [dispatch])

  useEffect(() => {
    if (shareFetchedData) {
      setName(shareFetchedData.Name)
      setShareType(shareFetchedData.Type)
      setServer(shareFetchedData.Server)
      setPort(shareFetchedData.Port)
      setLogin(shareFetchedData.Login)
      setPassword(shareFetchedData.Password)
      setResource(shareFetchedData.Resource)
      setPath(shareFetchedData.Path)
      const extra = shareFetchedData.ExtraSettings
      if (extra) {
        if (extra.AWSRegion) setAWSRegion(extra.AWSRegion)
        if (extra.PrivateKey) setPrivateKey(extra.PrivateKey)
      }

      dispatch(shareNav({ id: shareFetchedData.Id, name: shareFetchedData.Name }))
    }

    if (isNew) {
      // there won't be taskFetchedData === true for isNew === true case, so:
      dispatch(shareNav({ id: -1, name: 'New File Share' }))
    }
  }, [dispatch, isNew, shareFetchedData])

  const handleCreateOnClick = () => {
    mutateCreate({
      AWSRegion: awsRegion,
      Login: login,
      Name: name,
      Password: password,
      Path: path,
      Port: port,
      PrivateKey: privateKey,
      Resource: resource,
      Server: server,
      Type: shareType,
    })
  }

  const handleUpdateOnClick = () => {
    mutateUpdate({
      AWSRegion: awsRegion,
      Login: login,
      Name: name,
      Password: password,
      Path: path,
      Port: port,
      PrivateKey: privateKey,
      Resource: resource,
      Server: server,
      Type: shareType,
    })
  }

  return (<>
    <Breadcrumbs/>
    { (shareIsLoading || createIsLoading) ? <Spin tip="Loading..."/> : <Form
      layout={'vertical'}
      form={form}
    >
      <Form.Item label="File Share Name">
        <Input placeholder="File Share Name, ex: My File Share" value={name} onChange={(e) => setName(e.target.value)}/>
      </Form.Item>
      <Form.Item label="Share Type (Protocol)">
        <Select
          placeholder={'Choose a server protocol'}
          onChange={(val) => setShareType(val)}
          value={shareType}
          options={typeOptions}
          // disabled={!isNew}
          style={{ width: 500 }}
        />
      </Form.Item>
      <Form.Item label="Server" style={{ width: 500 }}>
        <Input placeholder="Server" value={server} onChange={(e) => setServer(e.target.value)}/>
      </Form.Item>
      <Form.Item label="Port">
        <InputNumber value={port} onChange={(val) => setPort(val)}/>
      </Form.Item>
      <Form.Item label="Login" style={{ width: 500 }}>
        <Input placeholder="Login" value={login} onChange={(e) => setLogin(e.target.value)}/>
      </Form.Item>
      <Form.Item label="Password" style={{ width: 500 }}>
        <Input.Password placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
      </Form.Item>
      <Form.Item label="Resource">
        <Input placeholder="Resource" value={resource} onChange={(e) => setResource(e.target.value)}/>
      </Form.Item>
      <Form.Item label="Path">
        <Input placeholder="Path" value={path} onChange={(e) => setPath(e.target.value)}/>
      </Form.Item>
      { shareType === 2 && // S3
        <>
          <Form.Item label="AWS Region" style={{ width: 500 }}>
            <Input placeholder="ex: us-east-1" value={awsRegion} onChange={(e) => setAWSRegion(e.target.value)}/>
          </Form.Item>
        </> }
      { shareType === 3 && // SFTP
        <>
          <Form.Item label="Private Key">
            <Input placeholder="ex: -----BEGIN OPENSSH PRIVATE KEY-----......"
              value={privateKey} onChange={(e) => setPrivateKey(e.target.value)}/>
          </Form.Item>
        </> }
      <Form.Item>
        { isNew ?
          <Button type="primary" onClick={handleCreateOnClick} disabled={createIsLoading}>Create</Button>
          : <Button type="primary" onClick={handleUpdateOnClick} disabled={updateIsLoading}>Save</Button>
        }
      </Form.Item>
    </Form> }
  </>

  )
}

SharePage.propTypes = {
  isNew: PropTypes.bool,
}

export default SharePage
