function extractErr (err, errPref = 'error:', logErr = true) {
  logErr && console.error(err)

  let backupErrMsg = err ? err.message : 'undefined error'
  let errMsg = (err && err.response && err.response.data && err.response.data.message) ?
    err.response.data.message :
    backupErrMsg

  return `${errPref} ${errMsg}`
}

export {
  extractErr,
}
