import { Button, Col, Modal, Row, Spin, Table, Typography } from 'antd'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import EnrollmentForm from './EnrollmentForm'
import {
  accountData,
  cancelSubscription,
  getAccountDetails,
  getUpcomingInvoice,
  subscriptionData,
  upcomingInvoiceData,
} from '../../lib/FTWebApi'
import { openError, openSuccess } from '../Notification'

const columns = [
  {
    dataIndex: 'description',
    title: 'Description',
  },
  {
    dataIndex: 'quantity',
    title: 'QTY',
  },
  {
    dataIndex: 'amount',
    render: (amount) => `$${amount / 100}`,
    title: 'Amount',
  },
]

const CancelSubscription = () => {
  const queryClient = useQueryClient()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { mutate: mutateDelete, isLoading: deleteIsLoading } = useMutation(cancelSubscription, {
    onError: (err) => {
      console.error(err.response.data)
      let errMsg = `There was an error canceling Subscription: ${err.message}`
      const responseErr = err.response && err.response.data && err.response.data.error
      if (responseErr) errMsg += ` ${responseErr}`
      openError('Error', errMsg)
    },
    onSettled: () => {
      queryClient.invalidateQueries(subscriptionData).then()
    },
    onSuccess: () => {
      openSuccess('Success', 'Subscription has been successfully canceled!')
      setIsModalOpen(false)
      queryClient.setQueryData(subscriptionData, null)
      queryClient.setQueryData(upcomingInvoiceData, null)
    },
  })

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    !deleteIsLoading && mutateDelete()
  }

  const handleCancel = () => {
    !deleteIsLoading && setIsModalOpen(false)
  }

  return (
    <>
      <Row justify="end" align="middle" style={{ marginTop: 10 }}>
        <Col>
          <Button danger onClick={showModal}>
            Cancel Subscription
          </Button>
        </Col>
      </Row>
      <Modal
        title="Cancel Subscription"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes, cancel it"
        cancelText="No, keep it"
        okButtonProps={{ danger: true, disabled: deleteIsLoading, loading: deleteIsLoading }}
        cancelButtonProps={{ disabled: deleteIsLoading }}
      >
        <p>Are you sure you want to cancel your Subscription?</p>
      </Modal>
    </>
  )
}

const UpcomingInvoice = () => {
  const [finalData, setFinalData] = useState([])
  const { isLoading: isLoadingInvoiceData, data: fetchedInvoiceData } =
    useQuery(upcomingInvoiceData, getUpcomingInvoice)
  const { isLoading: isLoadingAccountData, data: fetchedAccountData } =
    useQuery(accountData, getAccountDetails)

  const [curPeriodStart, setCurPeriodStart] = useState(moment())
  const [curPeriodEnd, setCurPeriodEnd] = useState(moment())

  useEffect(() => {
    if (fetchedInvoiceData) {
      setCurPeriodStart(moment.unix(fetchedInvoiceData.period_start))
      setCurPeriodEnd(moment.unix(fetchedInvoiceData.period_end))
      fetchedInvoiceData.lines && setFinalData(fetchedInvoiceData.lines.data)
    }
  }, [fetchedInvoiceData])

  if (isLoadingInvoiceData || isLoadingAccountData) {
    return <Spin tip="Loading..."/>
  }

  return (
    <>
      { !fetchedAccountData?.HasPaymentMethod && <>
        <Typography style={{ marginBottom: 15 }}>
          <Typography.Text>You don&apos;t have Subscription yet. Enroll into FileBro.io Monthly Subscription
            in the form below or all your synchronization processes will be put on pause after the end
            of the Trial period.</Typography.Text>
        </Typography>
        <EnrollmentForm />
      </> }
      { fetchedInvoiceData && <>
        <Table
          columns={columns}
          dataSource={finalData}
          title={() => `Billing period: ${curPeriodStart.format('L')} - ${curPeriodEnd.format('L')}`}
          bordered
          pagination={false}
          rowKey={record => record.id}
          style={{ marginTop: 15 }}
          summary={() => (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2} align="right"><b>Subtotal</b></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>${ fetchedInvoiceData.subtotal / 100 }</Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2} align="right"><b>Total excluding tax</b></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>${ fetchedInvoiceData.total_excluding_tax / 100 }</Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2} align="right"><b>Total</b></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>${ fetchedInvoiceData.total / 100 }</Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2} align="right"><b>Amount due</b></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>${ fetchedInvoiceData.amount_due / 100 }</Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        <CancelSubscription />
      </> }
    </>
  )
}

export default UpcomingInvoice
