import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Form, Input, Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { useMutation } from 'react-query'
import { openError, openSuccess } from './Notification'
import { changePassword } from '../lib/FTWebApi'

const ChangePasswordModal = (props) => {
  const { hideModalCbk } = props
  const [form] = Form.useForm()
  const { mutate: mutateChangePass, isLoading: changePassIsLoading } = useMutation(changePassword(), {
    onError: (err) => {
      console.error(err.response.data)
      let errMsg = `There was an error changing password: ${err.message}`
      const responseErr = err.response && err.response.data && err.response.data.error
      if (responseErr) errMsg += ` ${responseErr}`
      openError('Error', errMsg)
    },
    onSuccess: () => {
      openSuccess('Success', 'Password has been changed!')
      hideModalCbk()
    },
  })

  const onFinish = (values) => {
    mutateChangePass(values)
  }

  const onFinishFailed = (errorInfo) => {
    console.warn('errors on the form:', errorInfo)
  }

  return <Modal
    title="Password Change"
    open={true}
    destroyOnClose={true}
    icon={ExclamationCircleOutlined}
    onOk={() => {
      form.submit()
    }}
    onCancel={() => {
      hideModalCbk()
    }}
    okButtonProps={{ disabled: changePassIsLoading }}
    okText={'Confirm'}
    cancelButtonProps={{ disabled: changePassIsLoading }}
    cancelText={'Cancel'}
  >
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Current password"
        name="CurrentPassword"
        rules={[
          {
            message: 'Please enter current password!',
            required: true,
            type: 'string',
          },
        ]}
      >
        <Input.Password/>
      </Form.Item>

      <Form.Item
        label="New password"
        name="NewPassword"
        rules={[
          {
            message: 'Please enter new password!',
            required: true,
          },
          {
            min: 6,
            type: 'string',
          },
        ]}
      >
        <Input.Password/>
      </Form.Item>

      <Form.Item
        label="Repeat new password"
        name="NewPasswordRepeat"
        rules={[
          {
            message: 'Please enter new password!',
            required: true,
          },
          {
            min: 6,
            type: 'string',
          },
        ]}
      >
        <Input.Password/>
      </Form.Item>
    </Form>
  </Modal>
}

ChangePasswordModal.propTypes = {
  hideModalCbk: PropTypes.func.isRequired,
}

export {
  ChangePasswordModal,
}
