import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Button, Col, Row, Typography } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { WEB_API } from '../../constants'
import { accountData, getAccountDetails, getPaymentIntentSecret, stripePaymentIntent } from '../../lib/FTWebApi'
import CustomSpinner from '../CustomSpinner'

// eslint-disable-next-line max-len
const stripePromise = loadStripe('pk_live_51LbCiTJJeY63FzZXcMKNSoRLj3isI33lBgNV6UlAUolcp1rr2loJheZwnGeEExzAXp1N531pVOwJIyzevnfa2rIg00tqVbXtTt')

const CheckoutForm = (props) => {
  const { user } = props
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const { error } = await stripe.confirmSetup({
      confirmParams: {
        return_url: `${WEB_API}/complete/${user.Id}`,
      },
      //`Elements` instance that was used to create the Payment Element
      elements,
    })


    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement/>
      <Button type="primary" htmlType="submit" disabled={!stripe} style={{ marginTop: 15 }}>Submit</Button>
      { /* Show error message to your customers */ }
      { errorMessage && <div>{ errorMessage }</div> }
    </form>
  )
}

CheckoutForm.propTypes = {
  user: PropTypes.shape({
    Email: PropTypes.string.isRequired,
    Id: PropTypes.number.isRequired,
    Login: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    StripeSubscriptionId: PropTypes.string.isRequired,
    StripeSubscriptionItemId: PropTypes.string.isRequired,
    StripeUserId: PropTypes.string.isRequired,
  }),
}

const CardDetailsContent = () => {
  const navigate = useNavigate()
  const { isLoading: isLoadingStripeUserData, data: fetchedStripeUserData } =
    useQuery(stripePaymentIntent, getPaymentIntentSecret)
  const { isLoading: isLoadingAccountData, data: fetchedAccountData } =
    useQuery(accountData, getAccountDetails)

  useEffect(() => {
    if (fetchedStripeUserData?.redirect) {
      // means we already everything needed and backend tells us that we're fine
      navigate(fetchedStripeUserData.redirect)
    }
  }, [fetchedStripeUserData, navigate])

  const options = fetchedStripeUserData ? {
    // passing the client secret obtained in step 2
    clientSecret: fetchedStripeUserData.ClientSecret,
    // Fully customizable with appearance API.
    // appearance: {/*...*/},
  } : null

  return (
    <div className="steps-content">
      <Typography>
        <Typography.Text>
          In order to create a subscription, we need a couple of details from you.
          Please, enter you name, email and billing address.
        </Typography.Text>
      </Typography>
      <Row style={{ marginBottom: 15, marginTop: 15 }}>
        <Col span={2}/>
        <Col span={8}>
          { !isLoadingStripeUserData && !isLoadingAccountData ?
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm user={fetchedAccountData}/>
            </Elements>
            : <CustomSpinner/> }
        </Col>
      </Row>
    </div>
  )
}

export default CardDetailsContent
