import { DeleteTwoTone, FolderAddTwoTone, ShareAltOutlined, SyncOutlined } from '@ant-design/icons'
import { Divider, List, Skeleton, Typography } from 'antd'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { getTransferLogs, transferLogsData } from '../../lib/FTWebApi'
import { linkToShare, linkToTask } from '../../lib/urls'
import { openError } from '../Notification'

const TransferLogsTable = () => {
  const queryClient = useQueryClient()
  const [finalData, setFinalData] = useState([])
  const [limit] = useState(25)
  const [offsetID, setOffsetID] = useState(0)
  const [total, setTotal] = useState(-1)

  const { mutate: mutateGetTransferLogs, isLoading: getTransferLogsIsLoading } = useMutation(getTransferLogs(), {
    onError: (err) => {
      console.error(err.response.data)
      let errMsg = `There was an error loading transfer logs: ${err.message}`
      const responseErr = err.response && err.response.data && err.response.data.error
      if (responseErr) errMsg += ` ${responseErr}`
      openError('Error', errMsg)
    },
    onSettled: () => {
      queryClient.invalidateQueries(transferLogsData).then()
    },
    onSuccess: (data) => {
      const d = data.data
      setFinalData([...finalData, ...d])
      setTotal(data.total)
      if (d && d.length > 0) {
        setOffsetID(d[d.length - 1].Id)
      }
      // openSuccess('Success', 'File Share has been successfully deleted!')
    },
  })

  useEffect(() => {
    mutateGetTransferLogs({ limit, offsetID })
  // eslint-disable-next-line
  }, [])

  const loadMoreData = () => {
    if (getTransferLogsIsLoading) return

    mutateGetTransferLogs({ limit, offsetID })
  }

  const renderItem = (item) => {
    return <>
      <List.Item key={item.Id}>
        <Typography.Paragraph style={{ marginBottom: 0 }}>
          <Typography.Paragraph  style={{ marginBottom: 0 }}>
            <Typography.Text style={{ marginRight: 5 }}>
              { item.Action === 0 && <FolderAddTwoTone twoToneColor={'green'}/> }
              { item.Action === 1 && <DeleteTwoTone twoToneColor={'red'}/> }
            </Typography.Text>
            <Typography.Text>{ item.Path }{ item.Path !== '' && '/' }</Typography.Text>
            <Typography.Text style={{ marginRight: 5 }}>{ item.FileName }</Typography.Text>
            { item.Size >= 0 && <Typography.Text>
              ({ item.Size } bytes)
            </Typography.Text> }
          </Typography.Paragraph>
          <Typography.Paragraph style={{ marginBottom: 0 }}>
            { item.Action === 0 && <Typography.Text style={{ marginRight: 5 }}>
              <ul style={{ marginBottom: 0 }}>
                <li>MD5: { item.MD5 }</li>
                <li>SHA1: { item.SHA1 }</li>
                <li>SHA256: { item.SHA256 }</li>
              </ul>
            </Typography.Text> }
          </Typography.Paragraph>
        </Typography.Paragraph>

        <Typography.Paragraph style={{ marginBottom: 0 }}>
          <ul style={{ listStyleType: 'none' }}>
            <li>
              <Link to={linkToShare(item.ShareId)}>
                <ShareAltOutlined /> Share { item.ShareId }
              </Link> | <Link to={linkToTask(item.META.TaskId)}>
                <SyncOutlined /> Task { item.META.TaskId }
              </Link>
            </li>
            <li><i>{ moment(item.TS).format('LLL') }</i></li>
          </ul>
        </Typography.Paragraph>
      </List.Item>
    </>
  }

  return (
    <>
      <div
        id="scrollableDiv"
        style={{
          border: '1px solid rgba(140, 140, 140, 0.35)',
          height: '80vh',
          overflow: 'auto',
        }}
      >
        <InfiniteScroll
          dataLength={finalData.length}
          next={loadMoreData}
          hasMore={total === -1 || finalData.length !== total}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1,
              }}
              active
            />
          }
          endMessage={<Divider plain>No more events 😬</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={finalData}
            renderItem={renderItem}
          />
        </InfiniteScroll>
      </div>
    </>
  )
}

export default TransferLogsTable
