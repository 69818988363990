import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Space, Table, Tag } from 'antd'
import React  from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { shareTypeMap, syncTypes } from '../../enums'
import { deleteTask, getTasks, tasksData } from '../../lib/FTWebApi'
import { openError, openSuccess } from '../Notification'

const confirmTaskDeletion = (id, name, okCbk) => {
  Modal.confirm({
    cancelText: 'Cancel',
    content: `You are about to delete the task "${name}". Are you sure you want to do that?`,
    icon: <ExclamationCircleOutlined/>,
    okText: 'OK',
    onOk: okCbk,
    title: 'Confirm',
  })
}

const TaskTable = () => {
  const queryClient = useQueryClient()
  const { isLoading, data } = useQuery(tasksData, getTasks)
  const finalData = (data && data.length) ? data : []

  const { mutate: mutateDelete, isLoading: deleteIsLoading } = useMutation(deleteTask(), {
    onError: (err) => {
      console.error(err.response.data)
      let errMsg = `There was an error deleting a Task: ${err.message}`
      const responseErr = err.response && err.response.data && err.response.data.error
      if (responseErr) errMsg += ` ${responseErr}`
      openError('Error', errMsg)
    },
    onSettled: () => {
      queryClient.invalidateQueries(tasksData).then()
    },
    onSuccess: () => {
      openSuccess('Success', 'Task has been successfully deleted!')
    },
  })

  const columns = [
    {
      dataIndex: 'Id',
      key: 'Id',
      title: 'ID',
    },
    {
      dataIndex: 'Name',
      key: 'Name',
      title: 'Name',
    },
    {
      dataIndex: 'SourceShare',
      key: 'SourceShare',
      render: (share) => {
        return <span><Tag>{ shareTypeMap[share.Type] }</Tag> { share.Name }</span>
      },
      title: 'Source Share',
    },
    {
      dataIndex: 'DestinationShare',
      key: 'DestinationShare',
      render: (share) => {
        return <span><Tag>{ shareTypeMap[share.Type] }</Tag> { share.Name }</span>
      },
      title: 'Destination Share',
    },
    {
      dataIndex: 'Type',
      key: 'Type',
      render: (typeId) => {
        const syncType = syncTypes.getTypeByValue(typeId)
        return <span><Tag color={syncType.color}>{ syncType.label }</Tag></span>
      },
      title: 'Sync Type',
    },
    {
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link"><Link to={`/tasks/${record.Id}`}><EditOutlined/></Link></Button>
          <Button
            type="text"
            onClick={() => confirmTaskDeletion(record.Id, record.Name, () => mutateDelete(record.Id))}
            shape="circle"
            icon={<DeleteOutlined/>}
            danger
          />
        </Space>
      ),
      title: 'Action',
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={finalData}
        rowKey={'Id'}
        loading={isLoading || deleteIsLoading}
      />
    </>
  )
}

export default TaskTable
