import { Steps } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import CardDetailsContent from './CardDetailsContent'
import CreateUserContent from './CreateUserContent'
import { accountData, getAccountDetails } from '../../lib/FTWebApi'
const { Step } = Steps

const steps = [
  { title: 'Create payment account' },
  { title: 'Enter card details' },
]

const EnrollmentForm = () => {
  const [current, setCurrent] = useState(0)

  const { isLoading: isLoadingAccountData, data: fetchedAccountData } =
    useQuery(accountData, getAccountDetails)

  useEffect(() => {
    if (fetchedAccountData?.StripeUserId && current === 0) {
      // skip the first page with user registration
      setCurrent(1)
    }
  }, [current, fetchedAccountData])

  return (
    <div className={'enrollment-form'}>
      <Steps current={current} className={'steps-heading'} status="process">
        { steps.map(item => (
          <Step key={item.title} title={item.title} />
        )) }
      </Steps>
      { !isLoadingAccountData && current === 0 && <CreateUserContent /> }
      { !isLoadingAccountData && current === 1 && <CardDetailsContent /> }
    </div>
  )
}

export default EnrollmentForm
