import { Breadcrumb } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { breadcrumbsIconMap } from '../enums'
import { selectNav } from '../state/navSlice'

function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1
  const Icn = route.icon ? breadcrumbsIconMap[route.icon] : null
  return (
    <>
      { Icn && <Icn /> }
      { last ? <span>{ route.breadcrumbName }</span>
        :<Link to={'/'+paths.join('/')}>{ route.breadcrumbName }</Link> }
    </>
  )
}

const Breadcrumbs = () => {
  const navState = useSelector(selectNav)

  return (
    <Breadcrumb className={'breadcrumbs'} itemRender={itemRender} routes={navState.breadcrumbs}>
    </Breadcrumb>
  )
}

export default Breadcrumbs
