import { CloudUploadOutlined, HomeOutlined, ShareAltOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons'

const HOME_ICON = 'home'
const SYNC_TASK_ICON = 'sync_task'
const FILE_SHARE_ICON = 'file_share'
const TRANSFER_LOG_ICON = 'transfer_log'
const ACCOUNT_ICON = 'account'

const breadcrumbsIconMap = {
  [ACCOUNT_ICON]: UserOutlined,
  [FILE_SHARE_ICON]: ShareAltOutlined,
  [HOME_ICON]: HomeOutlined,
  [SYNC_TASK_ICON]: SyncOutlined,
  [TRANSFER_LOG_ICON]: CloudUploadOutlined,
}

const syncTypes = [
  { color: '#004100', label: 'Mirroring', value: 1 },
  { color: '#412000', label: 'Copy', value: 2 },
  { color: '#410041', label: 'Move', value: 3 },
]
syncTypes.getTypeByValue = (id) => syncTypes.find(({ value }) => value === id)

const shareTypeMap = {
  1: 'SMB (Samba)',
  2: 'S3',
  3: 'SFTP',
}

const transferLogAction = {
  0: 'Uploaded',
  1: 'Deleted',
}

export {
  ACCOUNT_ICON,
  HOME_ICON,
  SYNC_TASK_ICON,
  FILE_SHARE_ICON,
  TRANSFER_LOG_ICON,
  breadcrumbsIconMap,
  shareTypeMap,
  syncTypes,
  transferLogAction,
}
