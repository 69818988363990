import { Button, Form, Input, Select, Spin } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { syncTypes } from '../../enums'
import { createTask, getShares, getTask, sharesData, taskData, tasksData, updateTask } from '../../lib/FTWebApi'
import { taskNav, tasksNav } from '../../state/navSlice'
import Breadcrumbs from '../Breadcrumbs'
import { openError, openSuccess } from '../Notification'

const TaskPage = (props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { id: idParam } = useParams()
  const { isNew } = props
  const { isLoading: taskIsLoading, data: taskFetchedData } =
    useQuery(taskData(idParam), getTask(idParam), { enabled: !isNew })
  const { isLoading: sharesListIsLoading, data: sharesFetchedData } = useQuery(sharesData, getShares)
  const { mutate: mutateCreate, isLoading: createIsLoading } = useMutation(createTask(), {
    onError: (err) => {
      console.error(err.response.data)
      let errMsg = `There was an error creating a Task: ${err.message}`
      const responseErr = err.response && err.response.data && err.response.data.error
      if (responseErr) errMsg += ` ${responseErr}`
      openError('Error', errMsg)
    },
    onSettled: () => {
      queryClient.invalidateQueries(tasksData).then()
    },
    onSuccess: (data) => {
      openSuccess('Success', 'Task has been successfully created!')
      navigate('/tasks/' + data.Id)
    },
  })
  const { mutate: mutateUpdate, isLoading: updateIsLoading } = useMutation(updateTask(idParam), {
    onError: (err) => {
      console.error(err.response.data)
      let errMsg = `There was an error updating a Task: ${err.message}`
      const responseErr = err.response && err.response.data && err.response.data.error
      if (responseErr) errMsg += ` ${responseErr}`
      openError('Error', errMsg)
    },
    onSettled: () => {
      queryClient.invalidateQueries(tasksData).then()
    },
    onSuccess: () => {
      openSuccess('Success', 'Task has been successfully updated!')
    },
  })

  const [form] = Form.useForm()
  const [name, setName] = useState('')
  const [sourceShare, setSourceShare] = useState(null)
  const [destinationShare, setDestinationShare] = useState(null)
  const [syncType, setSyncType] = useState(syncTypes[0].value)
  const [shareOptions, setShareOptions] = useState(null)

  useEffect(() => {
    // we don't know the details of the task yet
    dispatch(tasksNav())
  }, [dispatch])

  useEffect(() => {
    if (taskFetchedData) {
      setName(taskFetchedData.Name)
      setSourceShare({ label: taskFetchedData.SourceShare.Name, value: taskFetchedData.SourceShare.Id })
      setDestinationShare({ label: taskFetchedData.DestinationShare.Name, value: taskFetchedData.DestinationShare.Id })
      setSyncType(taskFetchedData.Type)
      dispatch(taskNav({ id: taskFetchedData.Id, name: taskFetchedData.Name }))
    }

    if (isNew) {
      // there won't be taskFetchedData === true for isNew === true case, so:
      dispatch(taskNav({ id: -1, name: 'New Sync Task' }))
    }
  }, [dispatch, isNew, taskFetchedData])

  useEffect(() => {
    if (sharesFetchedData) {
      const opts = sharesFetchedData.map((share) => ({
        label: share.Name,
        value: share.Id,
      }))
      setShareOptions(opts)
    }
  }, [sharesFetchedData])

  const handleNameOnChange = (e) => {
    setName(e.target.value)
  }

  const handleCreateOnClick = () => {
    mutateCreate({
      DestinationShareID: destinationShare,
      Name: name,
      SourceShareID: sourceShare,
      Type: syncType,
    })
  }

  const handleUpdateOnClick = () => {
    mutateUpdate({
      Name: name,
      Type: syncType,
    })
  }

  return (<>
    <Breadcrumbs/>
    { (taskIsLoading || sharesListIsLoading || createIsLoading) ? <Spin tip="Loading..."/> : <Form
      layout={'vertical'}
      form={form}
    >
      <Form.Item label="Task Name">
        <Input placeholder="Task Name, ex: My First Task" value={name} onChange={handleNameOnChange}/>
      </Form.Item>
      <Form.Item label="Source Share">
        <Select
          placeholder={'Choose a source share'}
          onChange={(val) => setSourceShare(val)}
          value={sourceShare}
          options={shareOptions}
          disabled={!isNew}
          style={{ width: 500 }}
        />
      </Form.Item>
      <Form.Item label="Destination Share">
        <Select
          placeholder={'Choose a destination share'}
          onChange={(val) => setDestinationShare(val)}
          value={destinationShare}
          options={shareOptions}
          disabled={!isNew}
          style={{ width: 500 }}
        />
      </Form.Item>
      <Form.Item label="Synchronization Type">
        <Select
          placeholder={'Choose a synchronization type'}
          onChange={(val) => setSyncType(val)}
          value={syncType}
          options={syncTypes}
          style={{ width: 500 }}
        />
      </Form.Item>
      <Form.Item>
        { isNew ?
          <Button type="primary" onClick={handleCreateOnClick} disabled={createIsLoading}>Create</Button>
          : <Button type="primary" onClick={handleUpdateOnClick} disabled={updateIsLoading}>Save</Button>
        }
      </Form.Item>
    </Form> }
  </>

  )
}

TaskPage.propTypes = {
  isNew: PropTypes.bool,
}

export default TaskPage
