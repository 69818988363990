import { Button, Row } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const CreateShareButton = () => {
  const navigate = useNavigate()

  return (
    <Row justify="end" style={{ paddingBottom: 20 }}>
      <Button type="primary" onClick={() => navigate('/shares/create')}>Create</Button>
    </Row>
  )
}

export default CreateShareButton
