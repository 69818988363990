import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import CreateTaskButton from './CreateTaskButton'
import TaskTable from './TaskTable'
import { tasksNav } from '../../state/navSlice'
import Breadcrumbs from '../Breadcrumbs'

const TasksListPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(tasksNav())
  }, [dispatch])

  return (
    <>
      <Breadcrumbs/>
      <Row>
        <Col span={16}><p>Tasks that have been created under your account</p></Col>
        <Col span={8}><CreateTaskButton/></Col>
      </Row>
      <TaskTable/>
    </>
  )
}

export default TasksListPage
